import React, {useContext, useEffect, useState} from 'react';
import RegistrateStep from "./RegistrateStep";
import AuthStep from "./AuthStep";
import "./telephone_auth.css"
import {login, registration} from "../../../../../http/userAPI";
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import WelcomStep from "./WelcomStep";
import BasketStep from "./basketStapeComponent/BasketStep";
import RegistrationOrNot from "./registrationOrNot/RegistrationOrNot";
import {getAndMergeBasketItems} from "../../../../../http/basketApi";
import {  syncFavorites } from "../../../../../http/favoriteApi";

const ModalAuth = observer((props) => {

    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const [codeLimitErrorText, setCodeLimitErrorText] = useState('')

    const {settings, basket} = useContext(Context)

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    }, [])

    const {user} = useContext(Context);
    let navigate  = useNavigate();

    let registrationOnFlyBasket = basket.registrationOnFlyBasket

    const changeSmsCodeValue = (string) => {
        let replaceCode = string.replace(/[^\d]/g, '');
        user.setAuthSmsCode(replaceCode)
    }

    const goRegistrationStep = () => {
        user.setPhoneNumber('');
        user.setStepModals('registration');
        user.setCounterSecondCode(0);
    }

    const signIn = async () => {
        let replacePhoneNumber = user.phoneNumber.replace(/[^\d]/g, '');
        if(user.getMeCodeButton === "unactive") {
            return false;
        }
        let captchaToken = user.token;
        user.setGetMeCodeButton('unactive')
        let country = user.country;
        if(!country) {
            country = "Россия";
        }
        const response = await registration(replacePhoneNumber,country, captchaToken, deviceUUID, userAgent);
        if(response.data.success) {
            user.setStepModals('authStep');
            user.setCounterSecondCode(30);
        } else {
            setCodeLimitErrorText(response.data.error);
            user.setGetMeCodeButton('active');
        }
    }

    const signOn = async () => {

        if(user.authSmsCode.length < 4) {
            return false;
        }
        let replacePhoneNumber = user.phoneNumber.replace(/[^\d]/g, '');
        const data = await login(replacePhoneNumber,user.authSmsCode, deviceUUID)

        if(data.message){
            user.setCodeMessage(data.message);
            return data;
        }

        var favoritesIds = [];

        if(typeof settings.favoriteArray != 'undefined' && settings.favoriteArray != null && settings.favoriteArray.length > 0){
            settings.favoriteArray.map((item, index) =>
                favoritesIds.push(item.id)
            );
        }

        syncFavorites(favoritesIds, data.id).then(response => {
            if (response.status == 200 && response.data.res) {
                settings.setFavoriteArray(response.data.favs)
                localStorage.setItem("favoriteStorage",JSON.stringify(response.data.favs))
            }
        })

        user.setCodeMessage("");
        user.setPhoneNumber('');
        user.setUser(data);
        user.setIsAuth(true);

        let basketJson = localStorage.getItem('basketStorage');
        // мержим корзину с бекенда и корзину с локалстора
        getAndMergeBasketItems(data.id, basketJson).then(response => {
            if(response.items) {
                basket.setBasket(response.items);
            }
        })

        if(registrationOnFlyBasket) {
            navigate('/personal/basket/');
            user.setShadowOverlay(false);
            user.setOpenAuthModal(0)
            basket.setStartBasketNum(2);
            return false;
        }
        user.setStepModals('welcome')
    }

    const getCodeOneMoreTime = async () => {
        if(user.counterSecondCode > 0){
            return false;
        }

        let captchaToken = user.token;
        user.setGetMeCodeButton('unactive')
        let country = user.country;
        if(!country) {
            country = "Россия";
        }

        let replacePhoneNumber = user.phoneNumber.replace(/[^\d]/g, '');
        const response = await registration(replacePhoneNumber,country, captchaToken, deviceUUID, userAgent);
        user.setAuthSmsCode('');
        user.setCodeMessage("");
        if(response.data.success) {
            user.setCounterSecondCode(30);
        } else {
            setCodeLimitErrorText(response.data.error);
        }
    }

    return (
        <div className={"app_telephone_auth " + props.className}>

           <div className="wrapper_modal">
                <div className="closeModal" >
                    <div onClick={props.onClose} className={"closeModal_child"}>
                    </div>
                </div>
                {
                    user.stepModals === "registration" || user.stepModals === "authStep" || user.stepModals === "welcome" ?

                        <div className={"wrap_step_components"}>
                            <RegistrateStep
                                controllStateClass={user.stepModals === 'registration' ? "active" : 'unactive'}  registrationFunc={signIn}
                                codeLimitErrorText={codeLimitErrorText}
                            />
                            <AuthStep
                                controllStateClass={user.stepModals === 'authStep' ? "active" : 'unactive'}
                                codeValue={user.authSmsCode} codeChange={e => changeSmsCodeValue(e.target.value)}
                                goBack={e => goRegistrationStep()}
                                getCodeOneMoreTime={e=> getCodeOneMoreTime()}
                                getCodeButtonClass={user.counterSecondCode > 0 ? "unactive" : "active"}
                                loginFunc={e => signOn()}
                            />
                            <WelcomStep
                                controllStateClass={user.stepModals === 'welcome' ? "active" : 'unactive' }
                                closeModal={props.closeClick}
                            />
                        </div>
                    : ""
                }

                {
                    user.stepModals === "basketStep" || user.stepModals === "orderRegistrationOrNot" ?
                        <div className={"full_modal_wrapper"}>
                            {
                                user.stepModals === "basketStep" ?
                                    <BasketStep
                                        controllStateClass={user.stepModals === "basketStep" ? "active" : "unactive"}
                                    /> 
                                : ""
                            }
                            {
                                user.stepModals === "orderRegistrationOrNot" ?
                                    <RegistrationOrNot/>
                                : ""
                            }
                        </div>
                    : ""
                }
            </div>

        </div>
    );

});

export default ModalAuth;
