import React, {useContext, useEffect, useState} from 'react';
import './basketOneStepCalc.css'
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {priceToUserString} from "../../../http/basketApi";
import {Context} from "../../../index";
import BasketPromokod from '../../../components/UI/basketPromokod/BasketPromokod';
import BonusesBlock from '../../../components/UI/bonusesBlock/bonusesBlock';

const BasketOneStepCalc = observer(({basketItems, nameButton}) => {

    const {settings, basket} = useContext(Context)
    const [promoActivateCode, setPromoActiveteCode] = useState(basket.promocode??"");

    const Navigate = useNavigate();

    const basketGoOneStep = async () => {

        const result = await basket.upStepPage(basket.startBasketNum)
        if(!result) {
            window.scrollTo(0, 0)
            return false;
        }

        if(result.order) {
            Navigate("/order/id/"+result.order.custom_order_id);
        }
    }

    const isPromoHandler = (value) => {
        basket.setPromocode(value);
    }

    const handlerTargetValue = (e) => {
        let newStringTrim = e.trim()
        setPromoActiveteCode(newStringTrim);
    }

    const [nonFloatingButton, setNonFloatingButton] = useState(0)
    const [scroll, setScroll] = useState(false);
    const [trackScroll, setTrackScroll] = useState(true);


    useEffect(() => {
        let trackTopButtonDesignations = document.getElementById('trigger_non_button').offsetTop
        setNonFloatingButton(trackTopButtonDesignations)
        // document.addEventListener('DOMContentLoaded', function() {

            window.addEventListener("scroll", () => {

                let scrollY = Math.round(window.scrollY);
                setTrackScroll(scrollY)

                if (settings.projectHeight < nonFloatingButton && nonFloatingButton != 0 ) {
                    setScroll(scrollY < (trackTopButtonDesignations - settings.projectHeight))
                }
                return false;

            })
        // })

    },[trackScroll])


    return (
        <div className={"one_step_basket_container"}>
            <div className={"wrapper_price_basket"}>
                <div className={"one_step_order_price"}>
                    <span>Сумма заказа: </span>
                    <span className={"one_step_price_string"}>{priceToUserString(basket.priceNoSell)} ₽</span>
                </div>

                <div className={"one_step_order_price sales"}>
                    <span>Скидка: </span>
                    <span className={"one_step_price_string"}>{priceToUserString((basket.priceNoSell - basket.totalPrice - basket.bonusesConfirmed + basket.delPrice) > 0 ? (basket.priceNoSell - basket.totalPrice - basket.bonusesConfirmed + basket.delPrice) : 0)} ₽</span>
                </div>
                {
                    basket.isLoyaltyActive ? 
                        <div className={"one_step_order_price sales"}>
                            <span>Оплачено бонусами: </span>
                            <span className={"one_step_price_string"}>{priceToUserString(basket.bonusesConfirmed)} ₽</span>
                        </div>
                    : ""
                }
                <div className={"one_step_result_price"}>
                    <span className={"one_step_result_price_text"}>Итого:</span>
                    <span className={"one_step_result_price_sum"}>{priceToUserString(basket.totalPrice)} ₽</span>
                </div>
                {
                    basket.usedPromotions?.length ?
                        <div className='used_promotions'>
                            <span>Примененные акции:</span>
                            {
                                basket.usedPromotions.map((item, index) => 
                                    <div key={index} className={'info'}><span>{item}</span></div>
                                )
                            }
                        </div>
                        
                    : ""

                }


                
                {
                    basket.basketOverPrice ? 
                        <div>
                            <span className={"basket_price_info"}> Для оформления заказа на сумму более 80 000 р. необходимо связаться с менеджером интернет-магазина</span>
                        </div>
                    : ""
                }
                <div className={"basket_page_promo_wrapper"}>
                    <BasketPromokod
                        color={basket.promoNitificator.color}
                        notification={basket.promoNitificator.message}
                        value={promoActivateCode}
                        onChange={e => handlerTargetValue(e.target.value)}
                        onClick={e => isPromoHandler(promoActivateCode)}
                    />
                </div>
                {
                    basket.isLoyaltyActive ? 
                        <BonusesBlock/>
                    : ""
                }
                
            </div>
            <div id={"trigger_non_button"} className={"button_next_step_basket"}>
                <span
                    onClick={e => basketGoOneStep()}
                    className={basket.basketOverPrice ? "one_step_go_order_page disabled" : "one_step_go_order_page"}>
                {nameButton}
                </span>
            </div>
            <div className={scroll === false || basket.basketOverPrice ? "floating_button_wrapper hide" : "floating_button_wrapper"  }>
                <span
                    onClick={e => basketGoOneStep()}
                    className={"floating_button"}>
                {nameButton}
                </span>
            </div>
        </div>
    );
});

export default BasketOneStepCalc;