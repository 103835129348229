
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "./oneProductPage.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../../index";
import { Link, useNavigate, useParams } from "react-router-dom";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {
    getCategories,
    getDataForBreadcrumbs,
    getProduct,
    mindboxViewProduct,
    mindboxAddProductToProsmotrennyeItemList,
    getSliderData,
    getLastViewed
} from "../../../http/catalogApi";
import { countryArr } from "../../../utils/consts";
import Spiner from "../../administrator/spiner/Spiner";
import ProductBreadcrumbs from "./productBreadcrumbs/ProductBreadcrumbs";
import Like from "../../../components/UI/Like/Like";
import { priceToUserString } from "../../../http/basketApi";
import { createPreOrder, сreateSertificateOrder } from "../../../http/orderApi";
import CheckBoxPolitic from "../../../components/UI/checkBoxPolitic/CheckBoxPolitic";
import { useLocation } from "react-router";
import ReceiptNotification from "./receiptNotification";
import OfferSliderProduct from "./OfferSliderProduct";
import { pushGoogleAnalyticProductAction } from "../../../utils/googleAnalytic";
import InputMask from "react-input-mask";
import NotFound from "../../content/notFound/NotFound";
import { getCommonContent } from "../../../http/contentApi";
import OptionCountry from "../../../components/header/MainHeaderLine/IconsBar/modalAuth/RegistrationForm/OptionCountry";
import FastOrderPopup from "./fastOrderPopup";
import Modal from "../../../components/Modal/Modal";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation]);

const OneProductPage = observer((props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { settings, user, basket, lastViewedStore } = useContext(Context)

    const params = useParams();
    const Navigate = useNavigate();

    const apiUrlWithoutSlash = process.env.REACT_APP_API_URL.slice(0, -1);
    const parrent = params.parrentCategory;
    const child = params.childCategory;
    const third = params.thirdCategory;
    const fourth = params.fourthCategory;
    const code = params.code;
    const [oneProduct, setOneProduct] = useState(null);
    const [parent, setParent] = useState(null);
    const [activeChild, setActiveChild] = useState(null);
    const [breadcrumbsParams, setBreadcrumbsparams] = useState(null);
    const [goRender, setGoRender] = useState(false);
    const [activeSizeChildProduct, setActiveSizeChildProduct] = useState("");
    const [productProps, setProductProps] = useState({
        title: "",
        description: "",
    });
    const [notFound, setNotFound] = useState(false);
    const [deviceUUID, setDeviceUUID] = useState("");
    const [userAgent, setUserAgent] = useState("");
    const [maskState, setMaskState] = useState(countryArr[0]);
    const [searchStateShow, setSearchStateShow] = useState("unactive");
    const [searchQuery, setSearchQuery] = useState("");
    const [lastViewed, setLastViewed] = useState([]);

    useEffect(() => {
        setDeviceUUID(
            document.cookie.replace(
                /(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
            )
        );
        setUserAgent(window.navigator.userAgent);
    }, []);

    const [sostavContent, setSostavContent] = useState(null);
    const [careSwitch, setCareSwitch] = useState("");
    const [activeCareIcons, setActiveCareIcons] = useState("");

    const stateCareSwitch = (item, index) => {
        setCareSwitch(item.text);
        setActiveCareIcons(index);
    };

    const [obmerContent, setObmerContent] = useState(null);
    const [allObmerContent, setAllObmerContent] = useState(null);
    const [activeObmerName, setActiveObmerName] = useState("");
    const [activeSertificateModal, setActiveSertificateModal] = useState(false);

    const wiewSertificateModal = () => {
        user.setShadowOverlay(true);
        setActiveSertificateModal(true);
    };

    const [userPreOrderFields, setUserPreOrderFields] = useState({
        name: "",
        telephone: "",
        email: "",
    });
    const [successState, setSuccessState] = useState(false);
    const [preOrderBtnClick, setPreOrderBtnClick] = useState(false);
    const [successStateModal, setSuccessStateModal] = useState(false);

    const userPreOrderChangeNameHandler = (value) => {
        setUserPreOrderFields({ ...userPreOrderFields, name: value });
    };
    const userPreOrderChangeTelephoneHandler = (value) => {
        if (!value) {
            setUserPreOrderFields({ ...userPreOrderFields, telephone: value }); //
        }
        setTelephoneCheckButton(value);
        setUserPreOrderFields({ ...userPreOrderFields, telephone: value });
    };
    const userPreOrderChangeEmailHandler = (value) => {
        setUserPreOrderFields({ ...userPreOrderFields, email: value });
    };

    const [errorPreOrderText, setErrorPreOrderText] = useState(null);

    const onClickPreOrderButtonHandler = () => {
        if (stateCheсkBoxPreorder === false) {
            setErrorPreOrderText("Примите соглашение");
            return false;
        }
        if (userPreOrderFields.name.length < 2) {
            setErrorPreOrderText("Укажите настоящее имя");
            return false;
        }
        if (userPreOrderFields.telephone.length < 2) {
            setErrorPreOrderText("Укажите настоящий телефон");
            return false;
        }
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (
            !emailPattern.test(userPreOrderFields.email) ||
            !userPreOrderFields.email
        ) {
            setErrorPreOrderText("Укажите настоящий Email");
            return false;
        }
        setErrorPreOrderText("");
        if (preOrderBtnClick) return;
        setPreOrderBtnClick(true);

        createPreOrder(
            preOrderInfo,
            userPreOrderFields,
            user.user,
            deviceUUID,
            userAgent
        ).then((response) => {
            if (response.error) {
                return false;
            }

            setSuccessState(true);
            setPreOrderInfo(null);
            setPreOrderBtnClick(false);
          
        });
    };

    const [nameProduct, setNameProduct] = useState([]);

    const [activeSliderComponents, setActiveSliderComponents] = useState(false);
    const [productComplects, setProductComplects] = useState("");
    const [productRecomendation, setProductRecomendation] = useState("");
    const [stateSwitchSlider, setStateSwitchSlider] = useState("image");

    const [scrollButtonBack, setScrollButtonBack] = useState(0);
    const [currentFilterCatalog, setCurrentFilterCatalog] = useState("");
    const [activeSizeId, setActiveSizeId] = useState("");
    const [deliveryContent, setDeliveryContent] = useState("");

    const [galleryModal, setGalleryModal] = useState({
        isActive: false,
        index: 0,
    });

    const handleZoomClick = useCallback(() => {
        setGalleryModal({
            ...galleryModal,
            isActive: true,
        });
    }, [galleryModal, setGalleryModal]);

    const handleProductModalClose = useCallback(() => {
        setGalleryModal({
            ...galleryModal,
            isActive: false,
        });
    }, [galleryModal, setGalleryModal]);
    const productModalRef = useRef();
    const [productModalScrollWidth, setProductModalScrollWidth] = useState(0);
    useEffect(() => {
        if (galleryModal.isActive) {
            setProductModalScrollWidth(window.innerWidth - (productModalRef.current?.offsetWidth || 0));
        }
    }, [productModalRef, galleryModal.isActive]);

    useEffect(() => {
        getProduct(parrent, child, code).then((response) => {
            // получаем торговое предложение => продукт => остальные торг предложения
            if (response && response.status !== 404) {
                response = response.data;
                //При переходе в торав обнуляем позицию скролла в каталоге и сохраняем значение в переменную
                setScrollButtonBack(settings.scrollStatusCatalog);
                settings.setScrollStatusCatalog(0);
                //При переходе в товар записывает значение фильтра и офнумяем
                setCurrentFilterCatalog(settings.stateFilerCatalog);
                settings.setStateFilerCatalog({
                    name: "по умолчанию",
                    code: "default",
                });
                setOneProduct(response.data.product);
                setParent(response.data);
                const { active_sliders, active_complects, active_recomendation } =
                    response.data.product;
                if (active_sliders) {
                    getSliderData(response.data.product.id).then((response) => {
                        const { complects, recomendation } = response.data;
                        setActiveSliderComponents(false);
                        if (active_complects && complects?.length > 0) {
                            setProductComplects(complects);
                            setActiveSliderComponents(true);
                            setStateSwitchSlider("image");
                        } else {
                            setStateSwitchSlider("like");
                        }
                        if (active_recomendation && recomendation?.length > 0) {
                            setProductRecomendation(recomendation);
                            setActiveSliderComponents(true);
                        }
                    });
                }

                if (lastViewedStore.lastViewed.length) {
                    getLastViewed(lastViewedStore.lastViewed).then(response => {
                        if (response.data?.length) {
                            setLastViewed(response.data)
                        }
                    })
                }

                if (response.data.product.sostav) {
                    setSostavContent(JSON.parse(response.data.product.sostav));
                }
                // Если обмера нет или его нельзя сортировать - ничего не делаем. => не выводим
                // Заодно устанавливаем активным самый минимальный размер
                if (response.data.product.obmer) {
                    let parseObmer = JSON.parse(response.data.product.obmer);

                    if (parseObmer[0]) {
                        let sortParseObmer = parseObmer.sort(
                            (a, b) => a.position - b.position
                        );

                        setActiveObmerName(sortParseObmer[0].size_name_obmer);
                        setObmerContent(sortParseObmer);
                        setAllObmerContent(parseObmer);
                    }
                }

                if (response.data?.product?.landry_product_table !== "" && response.data.product.landry_product_table.length) {
                    setActiveCareIcons(0);
                    setCareSwitch(response.data.product.landry_product_table[0].text);
                }

                let itemTosetActive = response.data.product.childProducts.filter(
                    (oneitem) => oneitem.code === code
                )[0];

                if (itemTosetActive) {
                    const title = itemTosetActive.title;
                    let description = "";
                    if (typeof itemTosetActive.product != "undefined") {
                        description =
                            itemTosetActive.product.description_product_content || "";
                    }

                    setProductProps({ title, description });
                    setActiveChild(itemTosetActive);

                    let str = itemTosetActive.name.toLowerCase(); // Здесь
                    let a = str.split("");
                    a[0] = a[0].toUpperCase();
                    let i = 0;
                    let s = "";
                    while (i < a.length) {
                        s = s + a[i];
                        i++;
                    }
                    setNameProduct(s);
                }
            } else {
                setNotFound(true);
                return;
            }
            getDataForBreadcrumbs(parrent, child, third, fourth).then((response) => {
                setBreadcrumbsparams(response);
                setGoRender(true);
            });
        });

        getCommonContent("delivery").then((response) => {
            if (response.success) {
                setDeliveryContent(response.content);
            }
        });
    }, []);

    useEffect(() => {
        if (activeChild && activeSizeChildProduct) {
            getCategories(activeChild.product.product_category).then(
                (categoriesResponse) => {
                    let category = "";
                    if (categoriesResponse.success) {
                        category = activeChild.product.product_category
                            .map((id) => categoriesResponse.data[id])
                            .join("/");
                        let productsArray = [];

                        let findSize = activeChild.child_product_size_field.find(
                            (item) => item.size_name === activeSizeChildProduct
                        );
                        if (findSize.isActive) {
                            if (activeChild.child_product_size) {
                                let findSizeWithId = activeChild.child_product_size.find(
                                    (item) => item.size_name === findSize.size_name
                                );
                                if (findSizeWithId) {
                                    productsArray.push({
                                        id:
                                            String(activeChild.id) +
                                            String(activeChild.color.id) +
                                            findSizeWithId.id,
                                        name: activeChild.product.name,
                                        price: activeChild.sell_price
                                            ? activeChild.sell_price
                                            : activeChild.parrent_price,
                                        category: category,
                                        variant: activeChild.color.color_name,
                                    });
                                }
                            }
                        }
                        window.dataLayer.push({
                            event: "ym-ecom",
                            eCategory: "ecommerce",
                            eAction: "detail",
                            ecommerce: {
                                currencyCode: "RUB",
                                detail: {
                                    products: productsArray,
                                },
                            },
                        });
                    }
                }
            );
        }
    }, [activeChild, activeSizeChildProduct]);

    const [imgProduct, setImgProduct] = useState([]);
    const [activLinkPhoto, setActivLinkPhoto] = useState("");

    useEffect(() => {
        if (!activeChild) {
            return false;
        }

        let parseImg = JSON.parse(activeChild.other_images);

        let object = { imageProd: [] };

        if (activeChild.img_first) {
            let previewsPhoto = object.imageProd;
            previewsPhoto.push({ linkImg: activeChild.img_first });
            object = { ...object, imageProd: previewsPhoto };
        }

        for (const oneImage of parseImg) {
            let arrayOnePhoto = object.imageProd;
            if (oneImage.one_image != null && oneImage.one_image != "") {
                arrayOnePhoto.push({ linkImg: oneImage.one_image });
                object = { ...object, imageProd: arrayOnePhoto };
            }
        }

        for (const twoImage of parseImg) {
            let arrayTwoPhoto = object.imageProd;
            if (twoImage.two_image != null && twoImage.two_image != "") {
                arrayTwoPhoto.push({ linkImg: twoImage.two_image });
                object = { ...object, imageProd: arrayTwoPhoto };
            }
        }

        for (const threeImage of parseImg) {
            let arrayThreePhoto = object.imageProd;
            if (threeImage.three_image != null && threeImage.three_image != "") {
                arrayThreePhoto.push({ linkImg: threeImage.three_image });
                object = { ...object, imageProd: arrayThreePhoto };
            }
        }

        for (const fourImage of parseImg) {
            let arrayFourPhoto = object.imageProd;
            if (fourImage.four_image != null && fourImage.four_image != "") {
                arrayFourPhoto.push({ linkImg: fourImage.four_image });
                object = { ...object, imageProd: arrayFourPhoto };
            }
        }

        setImgProduct([object][0].imageProd);

        let sortPositionSize = activeChild.child_product_size_field.sort(
            (a, b) => a.position - b.position
        );
        let filterNotAvailable = sortPositionSize.filter(
            (item) => item.size_name !== ""
        );

        let findSizeActive = filterNotAvailable.filter(
            (item) => item.isActive === true
        )[0];

        if (filterNotAvailable[0]) {
            setActiveSizeChildProduct(findSizeActive.size_name);
            setActiveSizeId(findSizeActive.sizeId);

            if (!findSizeActive.available) {
                setActiveButtonNotification(true);
            } else {
                setActiveButtonNotification(false);
            }
        }
    }, [activeChild]);

    useEffect(() => {
        if (activeChild) {
            lastViewedStore.addViewed(activeChild.id);
        }
    }, [activeChild])

    useEffect(() => {
        if (user.isAuth) {
            setUserPreOrderFields({
                name: user.user.name ? user.user.name : "",
                telephone: user.user.telephone ? user.user.telephone : "",
                email: user.user.email ? user.user.email : "",
            });
        }
    }, [user.isAuth]);
    // Стейты открытых вкладышей, НЕ СВЯЗАНЫ С КОНТЕНТОМ, это тригеры открытий

    const [descriptionState, setDescriptionState] = useState(false);
    const [deliveryState, setDeliveryState] = useState(false);
    const [sostavState, setSostavState] = useState(false);
    const [obmerState, setObmerState] = useState(false);
    const [callbackState, setCallbackState] = useState(false);

    function getCurrentObmerToSizeName() {
        if (!obmerContent) {
            return false;
        }
        let filterObmer = obmerContent.filter(
            (item) => item.size_name_obmer === activeObmerName
        )[0];
        return filterObmer;
    }

    const changeActiveProductToColor = (item) => {
        let isActiveOldColor = item.child_product_size_field.filter(
            (item) => item.size_name === activeSizeChildProduct
        );

        setActivLinkPhoto("");
        setActiveChild(item);
    };

    function getClassForSize(item) {
        if (item.available === false) {
            if (item.size_name === activeSizeChildProduct) {
                return "page_product_size_one_item not_available active";
            }
            return "page_product_size_one_item not_available";
        }
        if (item.size_name === activeSizeChildProduct) {
            return "page_product_size_one_item active";
        }
        return "page_product_size_one_item";
    }

    const [activeButtonNotification, setActiveButtonNotification] =
        useState(false);

    const changeSizeChildProduct = (item) => {
        if (!item.available) {
            setActiveSizeChildProduct(item.size_name);
            setActiveSizeId(item.sizeId);
            setActiveButtonNotification(true);
            return false;
        }
        setActiveSizeChildProduct(item.size_name);
        setActiveSizeId(item.sizeId);
        setActiveButtonNotification(false);
    };

    const addToBasketHandlerProductPage = (item, currentSize) => {
        basket.addProduct(item, currentSize);
    };

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [openFastOrderModal, setOpenFastOrderModal] = useState(false);
    const [orderModalInfo, setOrderModalInfo] = useState(null);

    const addToFieldsProductModal = (
        activeChild,
        activeSizeChildProduct,
        isFastOrder = false
    ) => {
        setOrderModalInfo({
            child_product_id: activeChild.id,
            colorId: activeChild.colorId,
            count: 1,
            currentSize: activeSizeChildProduct,
            item: activeChild,
            productId: activeChild.product.id,
        });
        if (isFastOrder) {
            setOpenFastOrderModal(true);
        } else {
            setOpenNotificationModal(true);
        }
        setSuccessStateModal(false);
        user.setShadowOverlay(true);
    };

    const [preOrderInfo, setPreOrderInfo] = useState(null);
    const [openPreOrderModal, setOpenPreOrderModal] = useState(false);

    const preOrderModalGo = (
        activeChild,
        userId,
        activeSizeChildProduct,
        count
    ) => {
        setPreOrderInfo({
            child_product_id: activeChild.id,
            colorId: activeChild.colorId,
            count: 1,
            currentSize: activeSizeChildProduct,
            item: activeChild,
            productId: activeChild.product.id,
        });
        setSuccessState(false);

        setOpenPreOrderModal(true);
        user.setShadowOverlay(true);
    };

    const closeModalPreOrder = () => {
        setPreOrderInfo(null);
        user.setShadowOverlay(false);
        setOpenPreOrderModal(false);
    };

    useEffect(() => {
        if (user.shadowOverlay !== true) {
            setPreOrderInfo(null);
            setOpenPreOrderModal(false);
            setActiveSertificateModal(false);
        }
    }, [user.shadowOverlay]);

    const countChangeHandler = (param) => {
        if (param === "min") {
            if (preOrderInfo.count === 1) {
                return false;
            }
            setPreOrderInfo({ ...preOrderInfo, count: preOrderInfo.count - 1 });
        }
        if (param === "max") {
            setPreOrderInfo({ ...preOrderInfo, count: preOrderInfo.count + 1 });
        }
        return false;
    };

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [stateCheсkBoxPreorder, setStateCheсkBoxPreorder] = useState(false);

    // требуемое расстояние между touchStart и touchEnd для определения в виде свайпа
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null); // в противном случае свайп срабатывает даже при обычных событиях касания
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        // добавьте сюда свою условную логику
        if (imgProduct !== "") {
            let lastElementArray = imgProduct.length;
            if (!activLinkPhoto) {
                setActivLinkPhoto(imgProduct[0].linkImg);
            } else {
                let indexLink = 0;
                let isFindСoincidenceLink = false;
                let customIndex = 0;
                for (const oneLinkSwipeImg of imgProduct) {
                    if (activLinkPhoto === oneLinkSwipeImg.linkImg) {
                        customIndex = indexLink;
                        isFindСoincidenceLink = true;
                    }
                    indexLink++;
                }

                if (isFindСoincidenceLink) {
                    if (isLeftSwipe) {
                        if (customIndex === lastElementArray - 1) {
                            setActivLinkPhoto(imgProduct[0].linkImg);
                            return false;
                        }
                        setActivLinkPhoto(imgProduct[customIndex + 1].linkImg);
                    }
                    if (isRightSwipe) {
                        if (customIndex === 0) {
                            setActivLinkPhoto(imgProduct[lastElementArray - 1].linkImg);
                            return false;
                        }
                        setActivLinkPhoto(imgProduct[customIndex - 1].linkImg);
                    }
                }
            }
        }
        return false;
    };

    // Кнопка назад, проверяет есть ли ссылка назад, если нет то в каталог
    const location = useLocation();
    const navigate = useNavigate();
    const hasPreviousState = location.key !== "default";

    //Отслеживаем клик назад и устанавливаем значение скролла страницы
    window.onpopstate = () => {
        settings.setScrollStatusCatalog(scrollButtonBack);
        settings.setStateFilerCatalog(currentFilterCatalog);
    };

    const handleClick = () => {
        if (hasPreviousState) {
            navigate(-1);
            settings.setScrollStatusCatalog(scrollButtonBack);
            settings.setStateFilerCatalog(currentFilterCatalog);
        } else if (child && parrent) {
            navigate("/catalog/" + parrent + "/" + child + "/");
        } else if (parrent) {
            navigate("/catalog/" + parrent + "/");
        } else {
            navigate("/catalog/");
        }
    };

    const nameProductLowerCase = () => {
        if (nameProduct) {
            var lower = nameProduct.toLowerCase();
            return lower;
        }
        return "";
    };

    let countryArray = countryArr;
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [fio, setFio] = useState("");
    const [openSelectorCountry, setOpenSelectorCountry] = useState(false);

    const [validSertText, setValidSertText] = useState("");

    useEffect(() => {
        setSelectedCountry(countryArray[0]);
    }, []);

    const validBuySertificate = () => {
        setValidSertText("");
        if (fio.length < 8) {
            setValidSertText("Пожалуйста, укажите свое ФИО.");
            return false;
        }
        let replacenumber = telephone.replace(/[^\d]/g, "");
        if (Number(replacenumber.length) !== Number(maskState.number)) {
            setValidSertText("Пожалуйста, укажите номер.");
            return false;
        }
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email) || !email) {
            setValidSertText("Укажите настоящий Email");
            return false;
        }
        let preOrderInfo = {
            child_product_id: activeChild.id,
            colorId: activeChild.colorId,
            count: 1,
            currentSize: activeSizeChildProduct,
            item: activeChild,
            productId: activeChild.product.id,
        };
        сreateSertificateOrder(
            preOrderInfo,
            { fio, telephone, email },
            user.user
        ).then((response) => {
            Navigate("/order/id/" + response.order.custom_order_id + "/");
        });
    };

    useEffect(() => {
        if (activeChild && activeSizeId) {
            const currentSize = activeChild.child_product_size.find(
                (item) => item.id === activeSizeId
            );
            const guid = currentSize?.childProduct_Size?.guid ?? activeChild.id;

            mindboxViewProduct(deviceUUID, userAgent, guid);
            mindboxAddProductToProsmotrennyeItemList(
                deviceUUID,
                userAgent,
                guid,
                activeChild.product.price
            );
        }
    }, [activeChild, activeSizeId]);

    if (notFound) {
        return <NotFound />;
    }

    const getOfferId = () => {
        let id =
            activeChild.id.toString() +
            activeChild.color.id.toString() +
            activeSizeId.toString();
        return id;
    };

    const ToggleShowContry = () => {
        if (searchStateShow === "unactive") {
            setSearchStateShow("active");
        } else {
            setSearchStateShow("unactive");
        }
    };

    const getSortedCountry = () => {
        if (searchQuery) {
            return [...countryArr].filter((country) =>
                country.name
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase())
            );
        }
        return countryArr;
    };

    const sortedArr = getSortedCountry();

    const changeModelInput = (object) => {
        setMaskState(object);
        setTelephoneCheckButton("");
        ToggleShowContry();
        user.setCountry(object.name);
    };

    const setTelephoneCheckButton = (number) => {
        user.setPhoneNumber(number);
        let replacenumber = number.replace(/[^\d]/g, "");
        //устанавливаем кликабельность кнопки "получить код"
        if (Number(replacenumber.length) === Number(maskState.number)) {
            user.setGetMeCodeButton("active");
        } else {
            user.setGetMeCodeButton("unactive");
        }
    };

    return (
        <div
            id={"one_product_page_wrapper"}
            style={{ marginTop: settings.headerHeight }}
            className={"one_product_page_wrapper"}
        >
            <ReceiptNotification
                setOpenNotificationModal={(e) => setOpenNotificationModal(e)}
                openNotificationModal={openNotificationModal}
                preNotificationModalInfo={orderModalInfo}
                setPreNotificationModalInfo={(e) => setOrderModalInfo(e)}
                oneProduct={oneProduct}
                setSuccessStateModal={(e) => setSuccessStateModal(e)}
                successStateModal={successStateModal}
            />
            <FastOrderPopup
                setOpenFastOrderModal={(e) => setOpenFastOrderModal(e)}
                openFastOrderModal={openFastOrderModal}
                orderModalInfo={orderModalInfo}
                setOrderModalInfo={(e) => setOrderModalInfo(e)}
                oneProduct={oneProduct}
                setSuccessStateModal={(e) => setSuccessStateModal(e)}
                successStateModal={successStateModal}
            />
            {activeChild &&
                activeChild.product.electron_sertificate &&
                activeSertificateModal ? (
                <div className={"modal_sertificate"}>
                    <div
                        onClick={(e) => closeModalPreOrder()}
                        className={"sertificate_close"}
                    >
                        <div className={"sertificate_close_child"}></div>
                    </div>
                    <div className={"modal_sertificate_no_pos"}>
                        <div className={"heading_sertificate_modal"}>
                            Купить электронный сертификат
                        </div>
                        <div className={"cute_form_wrapper"}>
                            <span className={"cute_notificator"}>
                                Просим заполнить корректно свои данные. Код сертификата будет
                                отправлен по смс, а также на указанный e-mail{" "}
                            </span>
                            <div className={"cute_field_wrapper"}>
                                <span className={"cute_field_notif"}>
                                    Ф.И.О<span className={"red_cute"}>*</span>
                                </span>
                                <input
                                    value={fio ? fio : ""}
                                    onChange={(e) => setFio(e.target.value)}
                                    className={"cute_input"}
                                    type="text"
                                />
                            </div>
                            <div className={"cute_field_wrapper"}>
                                <span className={"cute_field_notif"}>
                                    Номер телефона<span className={"red_cute"}>*</span>
                                </span>
                                <div className={"wrapper_for_cute_input"}>
                                    <div className={"box_for_select_country"}>
                                        <div
                                            onClick={(e) =>
                                                setOpenSelectorCountry(!openSelectorCountry)
                                            }
                                            className={"current_cut_country"}
                                        >
                                            <img src={selectedCountry.imgPath} alt="" />
                                        </div>
                                        {openSelectorCountry ? (
                                            <div className={"wrapper_for_w_selected"}>
                                                {countryArray.map((item, index) => {
                                                    return (
                                                        <div
                                                            onClick={(e) => (
                                                                setSelectedCountry(item),
                                                                setOpenSelectorCountry(false)
                                                            )}
                                                            className={"cute_one_selector"}
                                                            key={index}
                                                        >
                                                            <img src={item.imgPath} alt="" />
                                                            <span>{item.name}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <InputMask
                                        className={"cute_input anlg"}
                                        placeholder={selectedCountry.placeholder}
                                        mask={selectedCountry.mask}
                                        value={telephone ? telephone : ""}
                                        onChange={(e) => setTelephone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={"cute_field_wrapper"}>
                                <span className={"cute_field_notif"}>
                                    E-mail<span className={"red_cute"}>*</span>
                                </span>
                                <input
                                    value={email ? email : ""}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={"cute_input"}
                                    type="text"
                                />
                            </div>
                            <div className={"validator_cute_line"}>{validSertText}</div>
                            <div
                                onClick={(e) => validBuySertificate()}
                                className={"buy_sertificate_btn"}
                            >
                                Купить
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}

            {goRender && breadcrumbsParams && oneProduct ? (
                <div
                    className={"page_one_product_wrapper_for_components"}
                    itemScope
                    itemType="http://schema.org/Product"
                >
                    {oneProduct && (
                        <>
                            <Helmet>
                                <title>
                                    {productProps.title
                                        ? productProps.title
                                        : "Купить " +
                                        nameProductLowerCase() +
                                        " - " +
                                        oneProduct.articul +
                                        " в интернет-магазине YOU WANNA"}
                                </title>
                                <meta
                                    name="description"
                                    content={
                                        productProps.description
                                            ? productProps.description
                                            : nameProduct +
                                            " - " +
                                            oneProduct.articul +
                                            " в YOU WANNA! По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07."
                                    }
                                />
                                <link
                                    rel="canonical"
                                    href={
                                        apiUrlWithoutSlash +
                                        (activeChild.product && activeChild.code
                                            ? activeChild.product.product_url + activeChild.code
                                            : "") +
                                        "/"
                                    }
                                />
                            </Helmet>
                            <div
                                itemProp="aggregateRating"
                                itemScope
                                itemType="http://schema.org/AggregateRating"
                            >
                                <meta itemProp="ratingValue" content={5} />
                                <meta itemProp="reviewCount" content={17} />
                            </div>
                            <meta itemProp="brand" content="YOU-WANNA" />
                            <meta itemProp="name" content={oneProduct.name || "YOU WANNA"} />
                            <meta
                                itemProp="description"
                                content={productProps.description || ""}
                            />

                            <div
                                itemScope
                                itemProp="offers"
                                itemType="http://schema.org/Offer"
                            >
                                <meta itemProp="price" content={oneProduct.price} />
                                <meta itemProp="priceCurrency" content="RUB" />
                                <link
                                    itemProp="availability"
                                    href="https://schema.org/InStock"
                                />
                            </div>
                        </>
                    )}
                    <div
                        className={
                            openPreOrderModal
                                ? "pre_order_modal_for_new_order active"
                                : "pre_order_modal_for_new_order"
                        }
                    >
                        <div
                            className={
                                successState
                                    ? "pre_order_modal_wrapper_for_content non_border"
                                    : "pre_order_modal_wrapper_for_content"
                            }
                        >
                            <div className={"pre_order_modal_heading_line_and_exit"}>
                                {preOrderInfo && !successState ? (
                                    <span className={"order_modal_heading_text"}>
                                        {" "}
                                        Оформить предзаказ{" "}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <div
                                    onClick={(e) => closeModalPreOrder()}
                                    className={"order_modal_exit_div"}
                                ></div>
                            </div>
                            <div
                                className={
                                    successState
                                        ? "relative_container_for_pre_order non_border"
                                        : "relative_container_for_pre_order"
                                }
                            >
                                {preOrderInfo && !successState ? (
                                    <div className={"modal_content_pre_order"}>
                                        <div className="modal_content_pre_order_heading_line">
                                            Данный товар доступен только по предзаказу. Менеджер
                                            свяжется с Вами после оформления.{" "}
                                        </div>
                                        <div className={"modal_content_pre_order_wrapper_for_row"}>
                                            <div className={"modal_content_pre_order_img_container"}>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_API_URL +
                                                        "uploads/images/" +
                                                        preOrderInfo.item.productId +
                                                        "/" +
                                                        preOrderInfo.item.id +
                                                        "/" +
                                                        "214_" +
                                                        preOrderInfo.item.img_preview
                                                    }
                                                    alt={
                                                        (nameProduct ? nameProduct : "") +
                                                        "; цвет: " +
                                                        (preOrderInfo.item.color.color_name
                                                            ? preOrderInfo.item.color.color_name
                                                            : "")
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    "modal_content_pre_order_wrapper_for_column_content"
                                                }
                                            >
                                                <div className="modal_content_pre_order_name_line">
                                                    {preOrderInfo.item.name}
                                                </div>
                                                <div className={"modal_content_pre_order_color_name"}>
                                                    <span
                                                        className={"modal_content_pre_order_black_span"}
                                                    >
                                                        Цвет:{" "}
                                                    </span>{" "}
                                                    <span>{preOrderInfo.item.color.color_name}</span>
                                                </div>
                                                <div className={"modal_content_pre_order_color_name"}>
                                                    <span
                                                        className={"modal_content_pre_order_black_span"}
                                                    >
                                                        Размер:{" "}
                                                    </span>{" "}
                                                    <span>{preOrderInfo.currentSize}</span>
                                                </div>
                                                <div className={"modal_content_pre_order_color_name"}>
                                                    <span
                                                        className={"modal_content_pre_order_black_span"}
                                                    >
                                                        Количество:{" "}
                                                    </span>

                                                    <div
                                                        className={"wrapper_for_mini_count_controller_wp"}
                                                    >
                                                        <div
                                                            className={
                                                                "wrapper_for_mini_count_controller min"
                                                            }
                                                            onClick={(e) => countChangeHandler("min")}
                                                        ></div>
                                                        <div
                                                            className={
                                                                "wrapper_for_mini_count_controller center"
                                                            }
                                                        >
                                                            {preOrderInfo.count}
                                                        </div>
                                                        <div
                                                            className={
                                                                "wrapper_for_mini_count_controller max"
                                                            }
                                                            onClick={(e) => countChangeHandler("max")}
                                                        ></div>
                                                    </div>
                                                </div>
                                                {!preOrderInfo.item.price_hidden ? (
                                                    <div className={"modal_content_pre_order_color_name"}>
                                                        <span
                                                            className={"modal_content_pre_order_black_span"}
                                                        >
                                                            Цена:{" "}
                                                        </span>{" "}
                                                        <span className={"one_product_page_price"}>
                                                            {preOrderInfo.item.sell_price
                                                                ? priceToUserString(
                                                                    preOrderInfo.item.sell_price *
                                                                    preOrderInfo.count
                                                                ) + " ₽"
                                                                : ""}
                                                        </span>{" "}
                                                        <span
                                                            className={
                                                                preOrderInfo.item.sell_price
                                                                    ? "standard_price old_price"
                                                                    : "standard_price"
                                                            }
                                                        >
                                                            {priceToUserString(
                                                                oneProduct.price * preOrderInfo.count
                                                            ) + " ₽"}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className={"pre_order_fields_modal"}>
                                            <div className={"pre_order_fields_wrapper"}>
                                                <span>Имя *</span>
                                                <input
                                                    value={
                                                        userPreOrderFields.name
                                                            ? userPreOrderFields.name
                                                            : ""
                                                    }
                                                    onChange={(e) =>
                                                        userPreOrderChangeNameHandler(e.target.value)
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                            <div className={"pre_order_fields_wrapper"}>
                                                <span>Телефон *</span>
                                                <div className={"registration_select_class"}>
                                                    <div className={"phone_and_city_wrap"}>
                                                        <div
                                                            onClick={(e) => ToggleShowContry()}
                                                            className={"selected_input_wrapper"}
                                                        >
                                                            <img src={maskState.imgPath} alt="" />
                                                        </div>
                                                        <div className={"searchResults " + searchStateShow}>
                                                            <div className={"wrapSearchInput"}>
                                                                <input
                                                                    type="text"
                                                                    className={"searchInput"}
                                                                    placeholder={"Введите название страны"}
                                                                    value={searchQuery}
                                                                    onChange={(e) =>
                                                                        setSearchQuery(e.target.value)
                                                                    }
                                                                />
                                                            </div>

                                                            {sortedArr.length ? (
                                                                sortedArr.map((country, name) => (
                                                                    <OptionCountry
                                                                        onClick={(e) => changeModelInput(country)}
                                                                        key={name}
                                                                        country={country}
                                                                    />
                                                                ))
                                                            ) : (
                                                                <p>
                                                                    К сожалению, такой страны нет. Свяжитесь с
                                                                    менеджером.{" "}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <InputMask
                                                        placeholder={maskState.placeholder}
                                                        mask={maskState.mask}
                                                        value={
                                                            userPreOrderFields.telephone
                                                                ? userPreOrderFields.telephone
                                                                : ""
                                                        }
                                                        onChange={(e) =>
                                                            userPreOrderChangeTelephoneHandler(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className={"pre_order_fields_wrapper"}>
                                                <span>Email *</span>
                                                <input
                                                    onChange={(e) =>
                                                        userPreOrderChangeEmailHandler(e.target.value)
                                                    }
                                                    value={
                                                        userPreOrderFields.email
                                                            ? userPreOrderFields.email
                                                            : ""
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <span className={"pre_order_finally_valid_text"}>
                                            {errorPreOrderText ? errorPreOrderText : ""}
                                        </span>
                                        <span
                                            onClick={(e) => onClickPreOrderButtonHandler()}
                                            className={"pre_order_finally_button"}
                                        >
                                            Оформить
                                        </span>
                                        <CheckBoxPolitic
                                            setStateCheсkBox={(e) => setStateCheсkBoxPreorder(e)}
                                            stateCheсkBox={stateCheсkBoxPreorder}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {!preOrderInfo && successState ? (
                                    <div className={"pre_order_success"}>
                                        <div className="order_notificator">
                                            <div className="circle_success"></div>
                                            <span className="thank_you_for_order">
                                                <noindex>Спасибо за заказ!</noindex>
                                            </span>
                                            <div className="thank_text_container">
                                                <span className="thank_text_child">
                                                    Ваш заказ успешно оформлен.{" "}
                                                </span>
                                                <span className="thank_text_child">
                                                    В ближайшее время с вами свяжется менеджер.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>

                    {settings.projectWidth && settings.projectWidth > 768 ? (
                        <ProductBreadcrumbs
                            params={breadcrumbsParams}
                            product_name={nameProduct}
                            parrent_url={parrent}
                            child_url={child}
                        />
                    ) : (
                        ""
                    )}

                    <div id={getOfferId()} className={"product_page_content_container"}>
                        {settings.projectWidth && settings.projectWidth < 1024 ? (
                            <div className={"back_button_product_wrapper"}>
                                <div
                                    onClick={(e) => handleClick()}
                                    className={"back_button_product"}
                                >
                                    <svg
                                        width="33"
                                        height="22"
                                        viewBox="0 0 55 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 2L2 11.0454L12 20"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="square"
                                        />
                                        <line
                                            x1="2"
                                            y1="11"
                                            x2="55"
                                            y2="11"
                                            stroke="black"
                                            strokeWidth="2"
                                        />
                                    </svg>
                                    <span>Назад</span>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        <div className={"container_slider_sticky"}>
                            <div
                                onTouchStart={onTouchStart}
                                onTouchMove={onTouchMove}
                                onTouchEnd={onTouchEnd}
                                className={"product_page_first_image_container"}
                            >
                                <div
                                    className={"product_img_wrapper"}
                                    onClick={handleZoomClick}
                                >
                                    {activeChild.img_first ? (
                                        <div>
                                            {activLinkPhoto && activLinkPhoto !== "" ? (
                                                <img
                                                    itemProp="image"
                                                    id={"height_product_img"}
                                                    src={
                                                        process.env.REACT_APP_API_URL +
                                                        "uploads/images/" +
                                                        activeChild.productId +
                                                        "/" +
                                                        activeChild.id +
                                                        "/" +
                                                        "950_" +
                                                        activLinkPhoto
                                                    }
                                                    alt={
                                                        (nameProduct ? nameProduct : "") +
                                                        "; цвет: " +
                                                        (activeChild.color.color_name
                                                            ? activeChild.color.color_name
                                                            : "")
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    itemProp="image"
                                                    id={"height_product_img"}
                                                    src={
                                                        activeChild.img_first !== null &&
                                                            activeChild.img_first !== ""
                                                            ? process.env.REACT_APP_API_URL +
                                                            "uploads/images/" +
                                                            activeChild.productId +
                                                            "/" +
                                                            activeChild.id +
                                                            "/" +
                                                            "950_" +
                                                            activeChild.img_first
                                                            : "/files/images/noimg.png"
                                                    }
                                                    alt={
                                                        (nameProduct ? nameProduct : "") +
                                                        "; цвет: " +
                                                        (activeChild.color.color_name
                                                            ? activeChild.color.color_name
                                                            : "")
                                                    }
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {activLinkPhoto && activLinkPhoto !== "" ? (
                                                <img
                                                    itemProp="image"
                                                    id={"height_product_img"}
                                                    src={
                                                        process.env.REACT_APP_API_URL +
                                                        "uploads/images/" +
                                                        activeChild.productId +
                                                        "/" +
                                                        activeChild.id +
                                                        "/" +
                                                        "950_" +
                                                        activLinkPhoto
                                                    }
                                                    alt={
                                                        (nameProduct ? nameProduct : "") +
                                                        "; цвет: " +
                                                        (activeChild.color.color_name
                                                            ? activeChild.color.color_name
                                                            : "")
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    itemProp="image"
                                                    id={"height_product_img"}
                                                    src={
                                                        activeChild.img_preview !== null &&
                                                            activeChild.img_preview !== ""
                                                            ? process.env.REACT_APP_API_URL +
                                                            "uploads/images/" +
                                                            activeChild.productId +
                                                            "/" +
                                                            activeChild.id +
                                                            "/" +
                                                            "950_" +
                                                            activeChild.img_preview
                                                            : "/files/images/noimg.png"
                                                    }
                                                    alt={
                                                        (nameProduct ? nameProduct : "") +
                                                        "; цвет: " +
                                                        (activeChild.color.color_name
                                                            ? activeChild.color.color_name
                                                            : "")
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}

                                    <div className={"product_page_like_wrapper"}>
                                        <Like
                                            onAddInFavorite={() =>
                                                pushGoogleAnalyticProductAction("add_to_wishlist", [
                                                    activeChild,
                                                ])
                                            }
                                            itemProduct={{
                                                ...parent,
                                                id: activeChild.id,
                                                color: activeChild.color,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"product_img_array"}
                            >
                                {imgProduct
                                    ? imgProduct.map((item, index) => (
                                        <div
                                            onClick={(e) => {
                                                setActivLinkPhoto(item.linkImg);
                                                setGalleryModal({
                                                    ...galleryModal,
                                                    index: index,
                                                });
                                            }}
                                            key={index}
                                            className={
                                                activLinkPhoto === item.linkImg
                                                    ? "one_img_product active"
                                                    : "one_img_product"
                                            }
                                        >
                                            <img
                                                src={
                                                    process.env.REACT_APP_API_URL +
                                                    "uploads/images/" +
                                                    activeChild.productId +
                                                    "/" +
                                                    activeChild.id +
                                                    "/" +
                                                    "950_" +
                                                    item.linkImg
                                                }
                                                alt={
                                                    (nameProduct ? nameProduct : "") +
                                                    "; цвет: " +
                                                    (activeChild.color.color_name
                                                        ? activeChild.color.color_name
                                                        : "")
                                                }
                                            />
                                        </div>
                                    ))
                                    : ""}
                            </div>
                        </div>

                        <div className={"product_page_right_content_container"}>
                            <h1>
                                <div className={"product_page_h1_container"}>
                                    <div className={"page_product_heading"}>
                                        {nameProduct ? nameProduct + " " : ""}
                                    </div>
                                </div>
                                <div className={"page_product_atricul"}>
                                    <span>Артикул: {" " + oneProduct.articul}</span>
                                </div>
                            </h1>
                            {!activeChild.price_hidden ? (
                                <div className={"price_and_shares"}>
                                    <div className={"one_product_page_price_line"}>
                                        <span className={"one_product_page_price"}>
                                            {activeChild.sell_price
                                                ? priceToUserString(activeChild.sell_price) + " ₽"
                                                : ""}
                                        </span>{" "}
                                        <span
                                            className={
                                                activeChild.sell_price
                                                    ? "standard_price old_price"
                                                    : "standard_price"
                                            }
                                        >
                                            {priceToUserString(oneProduct.price) + " ₽"}
                                        </span>
                                    </div>
                                    {activeChild.code.toLowerCase().indexOf("gift") < 0 ? (
                                        <>
                                            <div className={"installment_wrapper"}>
                                            <img style={{marginRight: "5px"}} src={"/files/images/payments/ysplit.svg"}></img>
                                            <a
                                                href={"/shares/"}
                                                target={"_blank"}
                                                rel="noreferrer"
                                                className={"installment_child"}
                                            >
                                                <svg
                                                    width="120"
                                                    height="35"
                                                    viewBox="0 0 68 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        y="0.5"
                                                        width="68"
                                                        height="20"
                                                        rx="4"
                                                        fill="black"
                                                    />
                                                    <g clip-path="url(#clip0_30955_166)">
                                                        <path
                                                            d="M15.9392 6.5H14.6044V13.2689H15.9392V6.5Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M13.0711 6.87876H11.7363V13.6475H13.0711V6.87876Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M10.203 7.30162H8.86814V14.0716H10.203V7.30162Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M7.33482 7.72917H6L6.00001 14.5H7.33482L7.33482 7.72917Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M51.194 10.1063L49.0447 7.71671H47.7882V13.2495H49.1001V9.71946L51.0623 11.8109H51.3047L53.2332 9.71946V13.2495H54.5451V7.71671H53.2886L51.194 10.1063Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M60.795 7.71671L57.5435 11.3128V7.71671H56.2316V13.2495H57.4335L60.685 9.65338V13.2495H61.9969V7.71671H60.795Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M40.7222 9.74122C40.7222 10.6221 41.2039 11.316 41.9433 11.607L40.5568 13.2495H42.1625L43.4238 11.7553H44.7886V13.2495H46.1005V7.71671H42.7606C41.5154 7.71671 40.7222 8.56858 40.7222 9.74122ZM44.7895 8.93528V10.5713H43.0039C42.4082 10.5713 42.0886 10.2393 42.0886 9.75251C42.0886 9.26572 42.4194 8.93367 43.0039 8.93367L44.7895 8.93528Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M34.626 8.82326C34.5457 10.7261 34.1443 11.9543 33.3583 11.9543H33.1616V13.2825L33.3712 13.2938C34.9472 13.3817 35.8183 11.9987 35.9612 8.978H38.0341V13.2495H39.3436V7.71671H34.6702L34.626 8.82326Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M29.6877 7.6394C27.9134 7.6394 26.6369 8.86765 26.6369 10.4828C26.6369 12.1535 28.0258 13.3382 29.6877 13.3382C31.4187 13.3382 32.7635 12.0874 32.7635 10.4828C32.7635 8.87813 31.4187 7.6394 29.6877 7.6394ZM29.6877 12.01C28.685 12.01 28.0017 11.3572 28.0017 10.4828C28.0017 9.58655 28.6858 8.95147 29.6877 8.95147C30.6897 8.95147 31.3962 9.61557 31.3962 10.4828C31.3962 11.3499 30.6801 12.01 29.6877 12.01Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M25.2456 7.72714H20.6155L20.5713 8.8337C20.5055 10.4278 20.0896 11.9438 19.3036 11.9656L18.9399 11.9769V14.5L20.263 14.4974V13.2502H24.8594V14.4974H26.1937V11.9656H25.2456V7.72714ZM23.9337 11.9656H21.1341C21.6078 11.2467 21.8615 10.2062 21.9057 8.98924H23.9337V11.9656Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_30955_166">
                                                            <rect
                                                                width="56"
                                                                height="8"
                                                                fill="white"
                                                                transform="translate(6 6.5)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                            <div className={"installment_child"}>
                                                    <span className={"installment_text"}>
                                                        4 платежа по{" "}
                                                    </span>
                                                    <span className={"installment_price"}>
                                                        {activeChild.sell_price
                                                            ? activeChild.sell_price / 4 + " ₽"
                                                            : oneProduct.price / 4 + " ₽"}
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                ""
                            )}

                            <div className={"one_page_content_selectors_container"}>
                                {activeChild.code.toLowerCase().includes("gift") ? null : (
                                    <div className={"page_product_color_changer_wrapper"}>
                                        {activeChild.color ? (
                                            <div>
                                                <span className={"page_product_name_field"}>
                                                    Цвет:{" "}
                                                </span>{" "}
                                                {activeChild.color.color_name}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {
                                            <div className="page_product_color_changer">
                                                {oneProduct.childProducts
                                                    ? oneProduct.childProducts.map((item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                onClick={(e) =>
                                                                    changeActiveProductToColor(item)
                                                                }
                                                                className={
                                                                    activeChild.colorId === item.color.id
                                                                        ? "one_product_color_wrapper active"
                                                                        : "one_product_color_wrapper"
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        item.color.img
                                                                            ? {
                                                                                backgroundImage:
                                                                                    "url(" +
                                                                                    process.env.REACT_APP_API_URL +
                                                                                    "uploads/images/colors/" +
                                                                                    item.color.id +
                                                                                    "/" +
                                                                                    item.color.img +
                                                                                    ")",
                                                                            }
                                                                            : { backgroundColor: item.color.hex }
                                                                    }
                                                                    key={item.id}
                                                                    className={
                                                                        item.color.hex === "white" ||
                                                                            item.color.hex == "#ffffff"
                                                                            ? "product_page_color_circle active"
                                                                            : "product_page_color_circle"
                                                                    }
                                                                ></div>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div>
                                        }
                                    </div>
                                )}

                                <div className={"page_product_size_wrapper"}>
                                    {activeChild.code.toLowerCase().includes("gift") ? null : (
                                        <>
                                            <div className={"page_product_size_name_line"}>
                                                <span className={"page_product_size_name"}>
                                                    Размер:{" "}
                                                </span>
                                            </div>
                                            <div className={"page_product_sizes_container"}>
                                                {activeChild
                                                    ? activeChild.child_product_size_field.map(
                                                        (item, index) => {
                                                            if (item.isActive) {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        onClick={(e) =>
                                                                            changeSizeChildProduct(item)
                                                                        }
                                                                        className={getClassForSize(item)}
                                                                    >
                                                                        {item.size_name}
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    )
                                                    : ""}
                                            </div>
                                        </>
                                    )}

                                    {activeChild.product.electron_sertificate ? (
                                        <span
                                            className={"product_page_add_to_basket sertificat"}
                                            onClick={(e) => wiewSertificateModal()}
                                        >
                                            Купить сертификат
                                        </span>
                                    ) : (
                                        <div>
                                            {!activeChild.coming_soon ? (
                                                <div className={""}>
                                                    {activeButtonNotification === false ? (
                                                        <div
                                                            className={
                                                                "product_page_controll_buttons_offer_and_basket"
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    activeSizeChildProduct
                                                                        ? "product_page_add_to_basket"
                                                                        : "product_page_add_to_basket disactive"
                                                                }
                                                                onClick={(e) =>
                                                                    addToBasketHandlerProductPage(
                                                                        activeChild,
                                                                        activeSizeChildProduct
                                                                    )
                                                                }
                                                            >
                                                                Добавить в корзину
                                                            </span>
                                                            <span
                                                                onClick={(e) =>
                                                                    addToFieldsProductModal(
                                                                        activeChild,
                                                                        activeSizeChildProduct,
                                                                        true
                                                                    )
                                                                }
                                                                className={"product_page_go_offer"}
                                                            >
                                                                Купить в 1 клик
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                "product_page_controll_buttons_offer_and_basket"
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    activeSizeChildProduct
                                                                        ? "product_page_add_to_basket notification_button"
                                                                        : "product_page_add_to_basket disactive"
                                                                }
                                                                onClick={(e) =>
                                                                    addToFieldsProductModal(
                                                                        activeChild,
                                                                        activeSizeChildProduct
                                                                    )
                                                                }
                                                            >
                                                                Уведомить о поступлении
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        "product_page_controll_buttons_offer_and_basket"
                                                    }
                                                >
                                                    <span
                                                        onClick={(e) =>
                                                            preOrderModalGo(
                                                                activeChild,
                                                                user.user.id,
                                                                activeSizeChildProduct,
                                                                1
                                                            )
                                                        }
                                                        className={"one_product_page_pre_order"}
                                                    >
                                                        Оформить предзаказ
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className={"description_product_wrapper"}>
                                    {oneProduct && oneProduct.description_product_content ? (
                                        <div
                                            className={
                                                descriptionState
                                                    ? "product_page_shield_child_product active"
                                                    : "product_page_shield_child_product"
                                            }
                                        >
                                            <div
                                                onClick={(e) => setDescriptionState(!descriptionState)}
                                                className={"product_page_shield_name"}
                                            >
                                                <span>Детали</span>{" "}
                                                <span className={"arrow_to_controll_shield"}></span>
                                            </div>
                                            <div className={"product_page_child_items_container"}>
                                                <div
                                                    itemProp="description"
                                                    className={"product_page_description_product_text"}
                                                >
                                                    {oneProduct.description_product_content}
                                                </div>
                                                    {oneProduct.description_product_content.toLowerCase().includes('параметры модели') && <p class="product_page_warning">*Обратите внимание, что размеры могут отличаться, в зависимости от кроя и особенностей ткани.</p>
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {sostavContent[0] ? (
                                        <div
                                            className={
                                                sostavState
                                                    ? "product_page_shield_child_product active"
                                                    : "product_page_shield_child_product"
                                            }
                                        >
                                            <div
                                                onClick={(e) => setSostavState(!sostavState)}
                                                className={"product_page_shield_name"}
                                            >
                                                <span>Состав</span>{" "}
                                                <span className={"arrow_to_controll_shield"}></span>
                                            </div>

                                            <div className={"product_page_child_items_container"}>
                                                <div className={"product_page_wrapper_for_sostav"}>
                                                    {sostavContent.map((item, index) => {
                                                        return (
                                                            <div
                                                                className={"product_page_one_sostav"}
                                                                key={index}
                                                            >
                                                                <span key={index}>{item.name_sost}</span>
                                                                {item.fields
                                                                    ? item.fields.map((item, index) => {
                                                                        return (
                                                                            <span key={index}>{item.value}</span>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className={"product_page_child_items_container"}>
                                                <div className={"product_page_landrys_wrapper"}>
                                                    {oneProduct.landry_product_table
                                                        ? oneProduct.landry_product_table.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        onClick={(e) =>
                                                                            stateCareSwitch(item, index)
                                                                        }
                                                                        key={index}
                                                                        className={
                                                                            activeCareIcons === index
                                                                                ? "product_page_one_landry active"
                                                                                : "product_page_one_landry"
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_API_URL +
                                                                                "uploads/images/landry/" +
                                                                                item.id +
                                                                                "/" +
                                                                                item.img
                                                                            }
                                                                            alt="YouWanna - уход"
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                        : ""}
                                                </div>
                                                <div className={"product_page_one_landry_text"}>
                                                    {careSwitch}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {allObmerContent ? (
                                        <div
                                            className={
                                                obmerState
                                                    ? "product_page_shield_child_product active"
                                                    : "product_page_shield_child_product"
                                            }
                                        >
                                            <div
                                                onClick={(e) => setObmerState(!obmerState)}
                                                className={"product_page_shield_name"}
                                            >
                                                <span>Обмеры</span>{" "}
                                                <span className={"arrow_to_controll_shield"}></span>
                                            </div>
                                            <div className={"product_page_child_items_container"}>
                                                <div className={"obmer_wrapper_product_page"}>
                                                    <div className={"product_page_size_items"}>
                                                        <div className={"product_page_obmer_text"}>
                                                            Посмотреть обмеры размера:
                                                        </div>
                                                        <div className={"product_page_size_list"}>
                                                            {obmerContent.map((item, index) => {
                                                                return (
                                                                    <div className={"one_column_obmer"}>
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                item.size_name_obmer === activeObmerName
                                                                                    ? "product_page_size_button active"
                                                                                    : "product_page_size_button"
                                                                            }
                                                                            // className={"product_page_size_button"}
                                                                            onClick={(e) =>
                                                                                setActiveObmerName(item.size_name_obmer)
                                                                            }
                                                                        >
                                                                            {item.size_name_obmer}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>

                                                    <div className={"content_selection_size"}>
                                                        {getCurrentObmerToSizeName().fields
                                                            ? getCurrentObmerToSizeName().fields.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                "product_page_obmer_size_params"
                                                                            }
                                                                        >
                                                                            <div>{item.name}</div>
                                                                            <div
                                                                                className={"to_bottom_border_obmer"}
                                                                            ></div>
                                                                            <div>{item.value}</div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            : ""}
                                                        <div className={"description_size_model"}>
                                                            {allObmerContent.sizeModel
                                                                ? allObmerContent.sizeModel
                                                                : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {deliveryContent && (
                                        <div
                                            className={
                                                deliveryState
                                                    ? "product_page_shield_child_product active"
                                                    : "product_page_shield_child_product"
                                            }
                                        >
                                            <div
                                                onClick={(e) => setDeliveryState(!deliveryState)}
                                                className={"product_page_shield_name"}
                                            >
                                                <span>Варианты доставки</span>{" "}
                                                <span className={"arrow_to_controll_shield"}></span>
                                            </div>
                                            <div className={"product_page_child_items_container"}>
                                                <div
                                                    itemProp="description"
                                                    className={"product_page_description_product_text"}
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: deliveryContent,
                                                        }}
                                                    ></span>
                                                </div>
                                                <Link className={"link"} to="/about/delivery">
                                                    Подробнее об условиях доствки
                                                </Link>
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            callbackState
                                                ? "product_page_shield_child_product active"
                                                : "product_page_shield_child_product"
                                        }
                                    >
                                        <div
                                            onClick={(e) => setCallbackState(!callbackState)}
                                            className={"product_page_shield_name"}
                                        >
                                            <span>Задать вопрос</span>{" "}
                                            <span className={"arrow_to_controll_shield"}></span>
                                        </div>
                                        <div className={"product_page_child_items_container"}>
                                            <div className={"wrapper_call_container"}>
                                                <a
                                                    href={"tel:+79268877007"}
                                                    target={"blank"}
                                                    className={"one_call_element_wrapper"}
                                                >
                                                    <img
                                                        className={"icon_for_call"}
                                                        src="/files/images/call_phone.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <a
                                                    className={"one_call_element_wrapper"}
                                                    href={
                                                        "https://api.whatsapp.com/send/?phone=79268877007&text=Здравствуйте%2C+меня+интересует " +
                                                        window.location.href
                                                    }
                                                    target={"blank"}
                                                >
                                                    <img
                                                        className={"icon_for_call"}
                                                        src="/files/images/whats.png"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        lastViewed.length ?
                            <div className={"slider_product_wrapper"}>
                                <div className={"title_slider"}>
                                    <div className={"title_like active"}>
                                        <span>Вы недавно смотрели</span>
                                        <div className={"title_like_switch"}></div>
                                    </div>
                                </div>
                                <OfferSliderProduct
                                    productMokTest={lastViewed}
                                    parrent={parrent}
                                    child={child}
                                />
                            </div>
                            : ''
                    }
                    {activeSliderComponents ? (
                        <div className={"slider_product_wrapper"}>
                            <div
                                className={
                                    productComplects && productRecomendation
                                        ? "switch_slider active"
                                        : "title_slider"
                                }
                            >
                                {productComplects && productComplects !== "" ? (
                                    <div
                                        onClick={(e) => setStateSwitchSlider("image")}
                                        className={
                                            stateSwitchSlider === "image"
                                                ? "title_image active"
                                                : "title_image"
                                        }
                                    >
                                        <span> Купить образ</span>
                                        <div className={"title_image_switch"}></div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {productRecomendation && productRecomendation !== "" ? (
                                    <div
                                        onClick={(e) => setStateSwitchSlider("like")}
                                        className={
                                            stateSwitchSlider === "like"
                                                ? "title_like active"
                                                : "title_like"
                                        }
                                    >
                                        <span>Вам также может понравиться</span>
                                        <div className={"title_like_switch"}></div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {stateSwitchSlider === "image" ? (
                                <OfferSliderProduct
                                    productMokTest={productComplects}
                                    parrent={parrent}
                                    child={child}
                                />
                            ) : (
                                <OfferSliderProduct
                                    productMokTest={productRecomendation}
                                    parrent={parrent}
                                    child={child}
                                />
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <Spiner />
            )}

            {galleryModal.isActive && (
                <Modal
                    onClose={handleProductModalClose}
                    modalOverlayClassName="product-modal__overlay"
                    extraClass="product-modal__window"
                >
                    <div className="product-modal__wrapper" ref={productModalRef}>
                        <button
                            className="product-modal__close"
                            onClick={handleProductModalClose}
                            style={{
                                right: productModalScrollWidth + "px"
                            }}
                        >
                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-width="1px"
                                    vector-effect="non-scaling-stroke"
                                    stroke="currentColor"
                                    d="M5 5L12 12M12 12L19 19M12 12L5 19M12 12L19 5"
                                ></path>
                            </svg>
                        </button>
                        <Swiper
                            loop
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            centeredSlides={true}
                            initialSlide={galleryModal.index}
                        >
                            <span slot="container-start">
                                <div className="product-modal__navigation">
                                    <div class="swiper-button-prev"></div>
                                    <div class="swiper-button-next" style={{
                                        paddingRight: productModalScrollWidth + "px"
                                    }}></div>
                                </div>
                            </span>
                            {imgProduct &&
                                imgProduct.length &&
                                imgProduct.map((item, index) => (
                                    <SwiperSlide>
                                        <img
                                            className="product-modal__img"
                                            src={
                                                process.env.REACT_APP_API_URL +
                                                "uploads/images/" +
                                                activeChild.productId +
                                                "/" +
                                                activeChild.id +
                                                "/" +
                                                "950_" +
                                                item.linkImg
                                            }
                                            width={950}
                                            height={1425}
                                            alt={
                                                (nameProduct ? nameProduct : "") +
                                                "; цвет: " +
                                                (activeChild.color.color_name
                                                    ? activeChild.color.color_name
                                                    : "")
                                            }
                                        />
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </Modal>
            )}
        </div>
    );
});

export default OneProductPage;
