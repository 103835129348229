import React, {useContext} from 'react';
import './deliveryMethods.css';
import {priceToUserString} from "../../../http/basketApi";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {pushGoogleAnalyticProductAction} from "../../../utils/googleAnalytic";

const DeliveryMethods = observer((props) => {

    const {user, basket, delivery} = useContext(Context)

    const setActiveDeliveryHandler = (item) => {

        if(basket.basketItems && basket.basketItems.length) {
            const googleAnalyticBasketItems = basket.basketItems.map((basketItem) => (
                {
                    ...basketItem.item,
                    currentSize: basketItem.currentSize,
                    customProps: {
                        quantity: basketItem.count
                    }
                }
            ))
            pushGoogleAnalyticProductAction('add_shipping_info', googleAnalyticBasketItems, {shipping_tier: item.name})
        }
        delivery.setActive(item);
        delivery.setError('');
        delivery.clearCustomerInfo();

        if(delivery.officePvz) {
            delivery.setOfficePvz(null);
        }

        if(item.delivery_integration && item.delivery_map && (item.delivery_type === "sdek_pvz" || item.delivery_type === "yandex_pvz")) {
            basket.setFunctionalModalState(2);
            user.setShadowOverlay(true)
        }
    }

    return (
        <div className={"delivery_methods_wrapper"}>
            <div className={"actual_delivery_methods"}>
                {
                    delivery.allMethods.length ?
                        <div className={"delivery_response_wrapper"}>
                            <div className={"delivery_response_list"}>
                                <span className={"delivery_response_heading"}>Способ доставки:</span>
                                <div className={"delivery_methods_order_container"}>
                                    {delivery.allMethods.map((item) => {
                                        return(
                                            <div
                                                key={item.id}
                                                onClick={e => setActiveDeliveryHandler(item)}
                                                className={delivery.active?.id === item.id ? "one_delivery_method_table active" : "one_delivery_method_table"}>
                                                <div className={"one_delivery_title"}>
                                                    {item.name}
                                                </div>
                                                <div className={"delivery_info_wrapper"}>
                                                    <div className={"active_delivery_info_text"}>
                                                        {
                                                            delivery.officePvz && (delivery.type === 'sdek_pvz' || delivery.type === 'yandex_pvz') && delivery.active?.id === item.id ?
                                                                <span className={"sdec_active_pvz_info"}>Выбран пункт выдачи: {delivery.type === 'sdek_pvz' ? delivery.officePvz.location.address : delivery.officePvz.address_full}</span>
                                                            : ""
                                                        }
                                                        <span className={"active_delivery_first_text"} dangerouslySetInnerHTML={{__html: item.delivery_text}}/>
                                                        <div>
                                                            {
                                                                !item.show_room ?
                                                                    <span className={"active_delivery_price_text"}>Доставка: {item.dop_price ? priceToUserString(item.dop_price)+" ₽" : "бесплатно" } {item.after_price_text ? item.after_price_text : ""}</span>
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"delivery_error_validator"}>
                                { delivery.error ? delivery.error : ""}
                            </div>
                        </div>
                    :
                        <div className={"delivery_response_wrapper"}>
                            <span className={"delivery_response_heading"}>Способ доставки:</span>
                            <span className={"delivery_response_no_delivery_info"}> Пожалуйста, выберите свой город, чтобы увидеть доступные способы доставки </span>
                        </div>
                }
            </div>
        </div>
    );
});

export default DeliveryMethods;