import { makeAutoObservable } from "mobx";

export default class DeliveryStore {

    basketStore;
    dliveryStore = null;
    allMethods = [];
    active = null;
    error = null;
    price = 0;
    terms = null;
    officePvz = null;
    cityInfo = null;
    cityInfoError = '';
    customerInfo = {
        all: null,
        street: null,
        house: null,
        flat: null, // квартира
        front_door: null, // подьезд
        floor: null, // этаж
        intercom: null, // домофон
        name: "",
        surname: "",
        patronymic: "",
        telephone: "",
        email: "",
    }
    nameValidShield = '';
    telephoneValidShield = '';
    emailValidShield = '';
    addressValidShield = '';

    constructor(basketStore) {
        this.basketStore = basketStore;
        makeAutoObservable(this);
    }

    get type() {
        console.log('active', this.active)
        return this.active?.delivery_type || null;
    }

    clear() {
        this.setActive(null);
        this.setPrice(0);
        this.setOfficePvz(null);
    }

    setAllMethods(array) {
        this.allMethods = array;
    }

    setActive(object) {
        if (object) {
            this.price = object.dop_price;
        }
        this.active = object;
    }

    setError(string) {
        this.error = string;
    }

    setPrice(number) {
        this.price = number
    }

    setTerms(object) {
        this.terms = object;
    }

    setOfficePvz(object) {
        this.officePvz = object;
    }

    setCityInfo(object) {
        this.cityInfo = object;
    }

    setCityInfoError(string) {
        this.cityInfoError = string;
    }

    setCustomerInfo(object) {
        this.customerInfo = object;
    }

    clearCustomerInfo() {
        this.customerInfo = {
            all: null,
            street: null,
            house: null,
            flat: null,
            front_door: null,
            floor: null,
            intercom: null,
            name: "",
            surname: "",
            patronymic: "",
            telephone: "",
            email: "",
        }
    }

    setNameValidShield(string) {
        this.nameValidShield = string;
    }

    setTelephoneValidShield(string) {
        this.telephoneValidShield = string;
    }
    
    setEmailValidShield(string) {
        this.emailValidShield = string;
    }
    
    setAddressValidShield(string) {
        this.addressValidShield = string;
    }
}