import {useEffect, useRef, useState} from 'react';
import './style.scss'

export const SmartBannerJSX = ({headerRef}) => {
    const [smartbannerIsInit, setSmartbannerIsInit] = useState(false);
    const ref = useRef();

    const smarbannerAppUrl = () => {

        if (checkAgent() === 'android') {
            return 'https://play.google.com/store/apps/details?id=ru.youwanna.android';
        }

        if (checkAgent() === 'ios') {
            return 'https://apps.apple.com/ru/app/you-wanna/id6449462077';
        }

        return null;
    }
    const checkAgent = () => {
        const maxTouchPoints = window.navigator.maxTouchPoints;
        const userAgent = window.navigator.userAgent;

        if (/Android/i.test(userAgent)){
            return 'android';
        } else if ((!window.MSStream && !/X11|Linux|Windows/i.test(userAgent) && maxTouchPoints && maxTouchPoints > 0) || /iPhone|iPad|iPod/i.test(userAgent)) {
            return 'ios';
        }

        return null
    }

    const selfHeight = () => {
        try {
            return ref.current.offsetHeight;
        } catch(error) {
            return 0;
        }
    }

    const publish = () => {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)smartbanner_exited\s*=\s*([^;]*).*$)|^.*$/, '$1') === '1') {
            return false;
        }

        return true;
    }

    const exit = () => {
        document.cookie = `smartbanner_exited=1; path=/`;
    }

    function positionHeaderOnScroll() {
        let handle = null;
        let prevScrollY = 0;
        let scrollDirectionDown = false;
        
        const onScroll = () => {
            if (handle) {
                clearTimeout(handle);
            }
        
            handle = setTimeout(() => {
                if (!selfHeight()) return;

                if (window.scrollY > prevScrollY && !scrollDirectionDown) {
                    headerRef.current.animate([{top: 0}, {top: `-${selfHeight()}px`}], {duration: 100}).onfinish = () => {
                        scrollDirectionDown = true;
                        headerRef.current.style.top = scrollDirectionDown ? `-${selfHeight()}px` : '0';
                    };
                } 

                if (window.scrollY < prevScrollY && scrollDirectionDown) {
                    headerRef.current.animate([{top: `-${selfHeight()}px`}, {top: 0}], {duration: 100}).onfinish = () => {
                        scrollDirectionDown = false;
                        headerRef.current.style.top = scrollDirectionDown ? `-${selfHeight()}px` : '0';
                    };
                }

                prevScrollY = window.scrollY;
            }, 50);
        };

        window.addEventListener('scroll', onScroll);

        return function() {
            window.removeEventListener('scroll', onScroll);
        };
    }

    useEffect(() => {

        setTimeout(() => {
            if (publish() && checkAgent()) {
                const {height} = headerRef.current.getBoundingClientRect();

                setSmartbannerIsInit(true);
                headerRef.current.animate([{height: `${height - selfHeight()}px`}, {height: `${height}px`}], {duration: 100});
            }
        }, 10000);

        positionHeaderOnScroll();
    }, []);

    return <>
        {smartbannerIsInit &&
            <div ref={ref} className={`smartbanner${checkAgent() === 'android' ? ' smartbanner--android' : ''}`}>
                <span class="smartbanner__exit js_smartbanner__exit" ariaLabel="Close" onClick={
                    () => {
                        exit();
                        setSmartbannerIsInit(false);
                    }
                }>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M1 1L5.92127 6L1 11M11 11L6.07873 6L11 1.00001" stroke="black" stroke-width="2"/>
                    </svg>
                </span>
                <div class="smartbanner__icon">
                    <img src='/files/images/header/smartbanner/smart.svg' alt=''/>
                </div>
                <div class="smartbanner__info">
                    <div>
                        <div class="smartbanner__info__title">
                            <span class="smartbanner__info__title-text">YOU WANNA</span>
                            <svg class="smartbanner__info__title-star" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.8499 13.8249L8.99989 11.9249L12.1499 13.8499L11.3249 10.2499L14.0999 7.84988L10.4499 7.52488L8.99989 4.12488L7.54989 7.49988L3.89989 7.82488L6.67489 10.2499L5.8499 13.8249ZM4.32489 15.9229L5.56489 10.6099L1.44189 7.03788L6.87289 6.56788L8.99989 1.55688L11.1269 6.56688L16.5569 7.03688L12.4349 10.6089L13.6749 15.9219L8.99989 13.1019L4.32489 15.9229Z" fill="black" fill-opacity="0.5"/>
                                <path d="M5.8499 13.8249L8.99989 11.9249L12.1499 13.8499L11.3249 10.2499L14.0999 7.84988L10.4499 7.52488L8.99989 4.12488L7.54989 7.49988L3.89989 7.82488L6.67489 10.2499L5.8499 13.8249Z" fill="black" fill-opacity="0.5"/>
                            </svg>
                            <span class="smartbanner__info__title-rating">4.7</span>
                        </div>
                        <div class="smartbanner__info__details">Скачать приложение</div>
                    </div>
                </div>
                <a href={smarbannerAppUrl()} class="smartbanner__button js_smartbanner__button" rel="noopener" ariaLabel="Скачать">
                    <span class="smartbanner__button__label" >Скачать</span>
                </a>
            </div>
        }
    </>
}
