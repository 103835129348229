import React, { useContext, useEffect, useState } from 'react';
import './orderUserFields.css';
import { priceToUserString, getDeliveryAddress, getDeliveryToDoorPrice } from "../../../http/basketApi";
import { Context } from "../../../index";
import { findUserByNumber } from "../../../http/userAPI";
import { countryArr } from "../../../utils/consts";
import InputMask from "react-input-mask";
import { observer } from "mobx-react-lite";
import UserExist from "../userExist/UserExist";

const OrderUserFields = observer(() => {
    const { basket, user, delivery } = useContext(Context)
    const [informationLine, setInformationLine] = useState('Введите адрес доставки')
    const [deliverySearchQuery, setDeliverySearchQuery] = useState({ value: "", trigger: true });
    const [sdekAddressArray, setSdekAddressArray] = useState(null);
    const [deliveryAdressItem, setDeliveryAdressItem] = useState(null);
    const [deliveryCalendar, setDeliveryCalendar] = useState(null);

    useEffect(() => {
        setInformationLine('Введите адрес доставки')
        let redMask = countryArr.filter(item => delivery.active.country_iso_code === item.country_iso_code);
        if (redMask[0]) {
            basket.setCountryAndMask(redMask[0])
        }
        if (user.isAuth) {
            let reduceBasketAddresFields = {
                ...delivery.customerInfo,
                telephone: user.user.login ? user.user.login : delivery.customerInfo.telephone,
                name: user.user.name ? user.user.name : delivery.customerInfo.name,
                surname: user.user.surname ? user.user.surname : delivery.customerInfo.surname,
                patronymic: user.user.patronymic ? user.user.patronymic : delivery.customerInfo.patronymic,
                email: user.user.email ? user.user.email : delivery.customerInfo.email,
            }
            delivery.setCustomerInfo(reduceBasketAddresFields);
        }
    }, [])

    const surnameChangeHandler = (item) => {
        // только буквы
        if (/^[A-Za-z-а-яА-ЯЁё]+$/.test(item) || item === '') {
            if (item.length > 35) {
                return false;
            }
            delivery.setCustomerInfo({ ...delivery.customerInfo, surname: item })
        }
    }

    const nameChangeHandler = (item) => {
        if (delivery.nameValidShield) {
            delivery.setNameValidShield('');
        }
        // только буквы
        if (/^[A-Za-z-а-яА-ЯЁё]+$/.test(item) || item === '') {
            if (item.length > 35) {
                return false;
            }
            delivery.setCustomerInfo({ ...delivery.customerInfo, name: item })
        }
    }

    const patronymicChangeHandler = (item) => {
        // только буквы
        if (/^[A-Za-z-а-яА-ЯЁё]+$/.test(item) || item === '') {
            if (item.length > 35) {
                return false;
            }
            delivery.setCustomerInfo({ ...delivery.customerInfo, patronymic: item })
        }
    }

    const phoneChangeHandler = (item) => {

        if (delivery.telephoneValidShield) {
            delivery.setTelephoneValidShield('')
        }

        if (item.length > 25) {
            return false;
        }
        // Включается только если нет маски
        if (!basket.countryAndMask) {
            // Надо будет переделать, долго искал регулярку позволяющую юзать цифры и пустоту, остановился на цифрах.
            if (!/^\d{0,25}$/.test(item)) {
                if (item === "") {
                    delivery.setCustomerInfo({ ...delivery.customerInfo, telephone: item })
                }
                return false;
            }
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, telephone: item })

        if (!user.isAuth && item.length > 7) {
            findUserByNumber(item).then(response => {
                if (response.error) {
                    if (basket.findUserByNumber) {
                        basket.setFindUserByNumber("");
                    }
                    return false;
                }
                basket.setFindUserByNumber(response.message);
            })
        }
    }

    const switchPhoneWalidator = () => {
        basket.setCountryAndMask(null)
        delivery.setCustomerInfo({ ...delivery.customerInfo, telephone: "" })
    }

    const emailChangeHandler = (item) => {
        if (delivery.emailValidShield) {
            delivery.setEmailValidShield("");
        }
        if (item.length > 45) {
            return false;
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, email: item })
    }

    const frontDoorHandler = (item) => {
        if (item.length > 25) {
            return false;
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, front_door: item })
    }

    const intercomHandler = (item) => {
        if (item.length > 25) {
            return false;
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, intercom: item })
    }

    const floorHandler = (item) => {
        if (item.length > 25) {
            return false;
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, floor: item })
    }

    const flatHandler = (item) => {
        if (item.length > 25) {
            return false;
        }
        delivery.setCustomerInfo({ ...delivery.customerInfo, flat: item })
    }


    useEffect(() => {
        getDeliveryAddress(delivery.cityInfo.value, deliverySearchQuery.value).then(response => {
                basket.setOrderSearchQuery(deliverySearchQuery);

                if (!deliverySearchQuery.set && deliverySearchQuery.value.length > 0) {
                    delivery.setAddressValidShield("Пожалуйста, укажите корректный адрес");
                }

                if (deliveryAdressItem && !deliveryAdressItem.data.house) {
                    delivery.setAddressValidShield('Укажите дом')
                }

                if (!deliverySearchQuery.trigger) {
                    setSdekAddressArray(null);
                    return false;
                } else {

                    if (delivery.type === "sdek_courier" || delivery.type === "yandex_courier") {
                        setInformationLine('Введите адрес целиком, чтобы рассчитать доставку');
                    }
                }
                if (!response) {
                    setSdekAddressArray(null);
                    return false;
                }
                if (deliverySearchQuery.value && !response[0]) {
                    setSdekAddressArray(null);
                    basket.setDeliveryPrice(0);
                    setInformationLine('Если вы укажете адрес самостоятельно, менеджер свяжется с Вами для уточнения условий доставки. Укажите улицу и дом.');
                    delivery.setCustomerInfo({ ...delivery.customerInfo, all: null })
                    return false;
                }
                setSdekAddressArray(response)
            })
    }, [deliverySearchQuery])

    useEffect(() => {
        if (deliveryCalendar?.calendar_min && deliveryCalendar?.calendar_max ) {
            setInformationLine("Стоимость доставки: " + (basket.delPrice ? priceToUserString(basket.delPrice) + ' ₽' : 'бесплатно') + ". Время доставки: от " + deliveryCalendar.calendar_min + " до " + deliveryCalendar.calendar_max + " р. дней.");
        } else {
            setInformationLine("Стоимость доставки: " + (basket.delPrice ? priceToUserString(basket.delPrice) + " ₽" : " Бесплатно") + " " + delivery.active.delivery_text)
        }
    }, [basket.delPrice, deliveryCalendar])

    const selectedAdressHandler = (item) => {
        setDeliveryAdressItem(item);
        setDeliverySearchQuery({ ...deliverySearchQuery, value: item.value, trigger: false, set: true })

        if (!item.data.house) {
            delivery.setAddressValidShield("Укажите дом");
            return;
        }

        const reduceFields = {
            ...delivery.customerInfo,
            all: item,
            street: item.data.street_with_type ? item.data.street_with_type : "",
            house: item.data.house ? item.data.house : "",
            building: item.data.block &&  item.data.block_type== "стр"? item.data.block : "",
            housing: item.data.block &&  item.data.block_type== "к"? item.data.block : "",
            flat: item.data.flat ? item.data.flat : "",
            city: item.data.settlement_with_type ? item.data.settlement_with_type : item.data.city_with_type,
            region: item.data.region_with_type ? item.data.region_with_type : "",
            metro: item.data.metro ? item.data.metro : "",
            postal_code: item.data.postal_code ? item.data.postal_code : "",
            floor: null,
            front_door: null,
            intercom: null,
        }
        delivery.setCustomerInfo(reduceFields);
        delivery.setAddressValidShield('')

        getDeliveryToDoorPrice(item, delivery.type, basket.priceNoSell).then(response => {
            if (response.error) {
                setInformationLine(response.error);
                return;
            }
            delivery.setPrice(response.delivery_sum);
            if (response.calendar_min && response.calendar_max ) {
                setDeliveryCalendar({
                    calendar_min: response.calendar_min,
                    calendar_max: response.calendar_max,
                })
            } else {
                setDeliveryCalendar(null)
            }
        })
        setInformationLine("Стоимость доставки: " + (basket.delPrice ? priceToUserString(basket.delPrice) + " ₽" : " Бесплатно") + " " + delivery.active.delivery_text)
    }

    return (
        <div className={"basket_order_user_fields_wrapper"}>
            <span className={"basket_order_user_fields_heading"}>2. Получатель</span>
            <span className={"basket_order_user_fields_info_text"}>Введите ваши контактные данные</span>
            <div className={"first_basket_order_user_fields_wrapper"}>
                <span className={"basket_order_user_heading_fields"}>Ваши данные:</span>
                <div className={"basket_order_user_fields_container"}>
                    <div className={"basket_order_one_field_wrapper"}>
                        <span className={"fields_delivery_basket"}>Фамилия</span>
                        <input
                            value={delivery.customerInfo.surname ? delivery.customerInfo.surname : ""}
                            onChange={e => surnameChangeHandler(e.target.value)}
                            type="text" />
                        <span className={"order_validator_shield"}></span>
                    </div>
                    <div className={delivery.nameValidShield ? "basket_order_one_field_wrapper error" : "basket_order_one_field_wrapper"}>
                        <span className={"fields_delivery_basket"}>Имя<span className={"required_field"}></span> </span>
                        <input
                            value={delivery.customerInfo.name ? delivery.customerInfo.name : ""}
                            onChange={e => nameChangeHandler(e.target.value)}
                            type="text" />
                        <span className={"order_validator_shield"}>{delivery.nameValidShield}</span>

                    </div>
                    <div className={"basket_order_one_field_wrapper"}>
                        <span className={"fields_delivery_basket"}>Отчество</span>
                        <input
                            value={delivery.customerInfo.patronymic ? delivery.customerInfo.patronymic : ""}
                            onChange={e => patronymicChangeHandler(e.target.value)}
                            type="text" />
                        <div className={"basket_container_validate_and_info"}>
                            <span className={"basket_order_input_text_info"}>*обязательно при доставке почтой</span>
                            <span className={"order_validator_shield"}></span>
                        </div>
                    </div>

                </div>
            </div>
            <div className={"first_basket_order_user_fields_wrapper"}>
                <span className={"basket_order_user_heading_fields"}>Контакты:</span>
                <div className={"basket_order_user_fields_container"}>
                    <div className={delivery.telephoneValidShield ? "basket_order_one_field_wrapper error" : "basket_order_one_field_wrapper"}>
                        {
                            basket.countryAndMask ?
                                <div className={"custom_order_phone"}>
                                    <span className={"fields_delivery_basket"}>Телефон<span className={"required_field"}></span></span>
                                    <InputMask
                                        className={""}
                                        placeholder={"Телефон*"}
                                        value={delivery.customerInfo.telephone ? delivery.customerInfo.telephone : ""}
                                        onChange={e => phoneChangeHandler(e.target.value)}
                                        alwaysShowMask={true}
                                        mask={basket.countryAndMask.mask}
                                        maskChar={""}
                                        disabled={false}
                                    />

                                    <div className={"country_container_for_phone"}>
                                        <span
                                            onClick={e => switchPhoneWalidator()}
                                            className={"country_notification_shield"}>Другая страна</span>
                                        <div className={"country_order_flag_container"}>
                                            <img src={basket.countryAndMask.imgPath} alt="" />
                                        </div>

                                    </div>
                                </div>

                            :
                                <input
                                    value={delivery.customerInfo.telephone ? delivery.customerInfo.telephone : ""}
                                    onChange={e => phoneChangeHandler(e.target.value)}
                                    placeholder={"Телефон* формат 7XXX, если регион RU"} type="text" 
                                />
                        }

                        {
                            basket.findUserByNumber ?
                                <UserExist/>
                            : ""
                        }
                        <span className={"order_validator_shield"}>{delivery.telephoneValidShield}</span>
                    </div>
                    <div className={delivery.emailValidShield ? "basket_order_one_field_wrapper error" : "basket_order_one_field_wrapper"}>
                        <span className={"fields_delivery_basket"}>Email<span className={"required_field"}></span></span>
                        <input
                            value={delivery.customerInfo.email ? delivery.customerInfo.email : ""}
                            onChange={e => emailChangeHandler(e.target.value)}
                            type="text" />
                        <div className={"basket_container_validate_and_info"}>
                            <span className={"basket_order_input_text_info"}>*Придет чек об оплате</span>
                            <span className={"order_validator_shield"}>{delivery.emailValidShield}</span>
                        </div>

                    </div>
                    {
                        delivery.active?.delivery_type === 'sdek_courier' || delivery.active?.delivery_type === 'sdek_pvz' ?
                            <div className={"avoid_call"}>
                                <div
                                    onClick={e => basket.setAvoidCall(!basket.avoidCall)}
                                    className={basket.avoidCall === true ? "basket_order_subscribe_check_input active" : "basket_order_subscribe_check_input"}>
                                    <div className="child_checkbox_selector">
                                    </div>
                                </div>
                                <span>Не связываться со мной по телефону</span>
                            </div>
                        : ""
                    }
                    <div className={"basket_order_subscribe_wrapper subs"}>
                        <div
                            onClick={e => user.setActiveSubs(!user.activeSubs)}
                            className={user.activeSubs ? "basket_order_subscribe_check_input active" : "basket_order_subscribe_check_input"}></div>
                        <span className={"basket_order_subscribe_text"}>Я хочу подписаться на рассылку, чтобы первым получать новости о скидках и новых поступлениях</span>
                    </div>

                </div>
            </div>


            {
                (!delivery.officePvz && delivery.active.show_room === false) ?
                    <div className={"first_basket_order_user_fields_wrapper enf"}>
                        <div className={"user_support_fields_order_wrapper"}>
                            <span className={"basket_order_user_heading_fields"}>Адрес доставки:</span>
                            <div className={"basket_order_user_fields_container"}>
                                <span className={"fields_delivery_basket"}>Адрес<span className={"required_field"}></span></span>
                                {
                                    sdekAddressArray && deliverySearchQuery.value && sdekAddressArray[0] ?
                                        <div className={"search_street_support_container"}>
                                            {
                                                sdekAddressArray.map((item, index) => {
                                                    return (
                                                        <div
                                                            className={"search_street_support_one_item"}
                                                            onClick={e => selectedAdressHandler(item)}
                                                            key={index}>
                                                            {item.value}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    : ""
                                }

                                <div className={delivery.addressValidShield ? "basket_order_one_field_wrapper custom_styles error" : "basket_order_one_field_wrapper custom_styles"}>
                                    <input
                                        className={"no_margin_first_input"}
                                        value={deliverySearchQuery.value ? deliverySearchQuery.value : ""}
                                        onChange={e => setDeliverySearchQuery({ ...deliverySearchQuery, value: e.target.value, trigger: true, set: false })}
                                        placeholder={"Улица, дом, квартира"}
                                        type="text" />
                                    <span className={"order_validator_shield"}>{delivery.addressValidShield}</span>
                                    <span className={"basket_order_one_field_support_info"}>{informationLine}</span>
                                </div>

                             {/*   <div className={"basket_order_small_fields_wrapper"}>
                                    <div className={"basket_order_one_field_wrapper"}>
                                        <input
                                            value={delivery.customerInfo.flat ? delivery.customerInfo.flat : ""}
                                            onChange={e => flatHandler(e.target.value)}
                                            placeholder={"Квартира"}
                                            type="text" />
                                    </div>
                                </div>*/}

                                <div className={"basket_order_small_fields_wrapper"}>
                                    <div className={"basket_order_one_field_wrapper"}>
                                        <input
                                            value={delivery.customerInfo.floor ? delivery.customerInfo.floor : ""}
                                            onChange={e => floorHandler(e.target.value)}
                                            placeholder={"Этаж"}
                                            type="text" />
                                    </div>
                                </div>
                                <div className={"basket_order_small_fields_wrapper"}>
                                    <div className={"basket_order_one_field_wrapper"}>
                                        <input
                                            value={delivery.customerInfo.front_door ? delivery.customerInfo.front_door : ""}
                                            onChange={e => frontDoorHandler(e.target.value)}
                                            placeholder={"Подъезд"}
                                            type="text" />
                                    </div>
                                </div>
                                <div className={"basket_order_small_fields_wrapper"}>
                                    <div className={"basket_order_one_field_wrapper"}>
                                        <input
                                            value={delivery.customerInfo.intercom ? delivery.customerInfo.intercom : ""}
                                            onChange={e => intercomHandler(e.target.value)}
                                            placeholder={"Домофон"}
                                            type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : ""
            }
        </div>
    );
});

export default OrderUserFields;